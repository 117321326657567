import axios from 'axios';

import { buildPath } from '../../../api/base';
import TwilioChannel from './TwilioChannel';
import { ConversationType } from '../state/constants';

async function setupChannel(channel) {
  // Join the channel
  if (!channel) return;
  if (channel.channelState.status === 'joined') {
    return channel;
  }
  try {
    await channel.join();
  } catch (e) {
    // eslint-disable-next-line
    console.debug('[Twilio] Failed to join channel', channel, e);
  }
}

export async function createOrJoinChannel(client, name = 'general') {
  let channel;
  try {
    channel = await client.conversations.getConversationByUniqueName(name);
  } catch (e) {
    // If it doesn't exist, let's create it
    try {
      channel = await client.createConversation({
        uniqueName: name,
        friendlyName: `${name} Chat Channel`,
      });
    } catch (e) {
      // eslint-disable-next-line
      console.debug('[Twilio] channel create error', e);
      return null;
    }
  }
  await setupChannel(channel);
  return channel;
}

export function generateChannelName(...ids) {
  return ids.sort().join('-');
}

export async function wait(delay = 0) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

export async function createTwilioConversation(
  service,
  id,
  currentUserId,
  type = ConversationType.Active,
  attributes = {},
  currentConversation = null
) {
  const client = await service.getClient();
  let userData;
  if (type === ConversationType.Active) {
    try {
      const url = buildPath(`/user/${id}`);
      const {
        data: { data },
      } = await axios.get(url);

      userData = data;

      if (!userData) {
        // eslint-disable-next-line
        console.debug('[Twilio] Unable to retrieve user data');
        return null;
      }
      const user = await axios.post('/twilio-user', userData);
    } catch (e) {
      // eslint-disable-next-line
      console.debug('[Twilio] Unable to retrieve user data');
      return null;
    }
  }

  const channelName = generateChannelName([id, currentUserId]) + type;

  const pureChannel = await createOrJoinChannel(client, channelName);
  if (!pureChannel) {
    console.debug('[Twilio] Failed to create channel', channelName);
    return null;
  }

  try {
    await pureChannel.updateAttributes({
      ...attributes,
      type,
      user: userData,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.debug('[Twilio] updateAttributes error');
  }
  if (type === ConversationType.WhiteBoard) {
    const channel = await TwilioChannel.create(pureChannel);
    if (currentConversation?.classData?.students) {
      await Promise.all(currentConversation.classData.students?.map(s => {
        channel.channel.add(s.uuid_user);
      }));
      if (currentConversation.classData.tutor_user_uuid !== currentUserId) {
        channel.channel.add(currentConversation.classData.tutor_user_uuid);
      }
    } else if (currentConversation?.user) {
      channel.channel.add(currentConversation.user.id);
    }

  } else {
    try {
      await pureChannel.add(id);
    } catch (e) {
      // eslint-disable-next-line
      console.debug('[Twilio] User already in conversation');
    }
  }


  const conversation = {
    type,
    id,
    user:
      type === ConversationType.Active
        ? {
            ...userData,
            identity: userData.uuid,
          }
        : null,
    channelName,
    channelSid: pureChannel.sid,
    sentMessages: [],
    messages: [],
    unreadCount: 0,
    firstMessageIndex: 0,
    awaitingChannel: false,
  };

  return conversation;
}
