import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Avatar, Typography } from '@material-ui/core';
import PhoneCallback from '@material-ui/icons/PhoneCallback';

import { getApiImages } from '../../../helpers/general';
import { Link } from '../../../helpers/i18n';
import { asRoutes, getPageUrl } from '../../../utils/url-helper';

const useStyles = makeStyles(theme =>
  createStyles({
    'User-details': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',

      '& .MuiAvatar-root': {
        flexShrink: 0,
        marginRight: theme.spacing(1),
      },
    },

    'User-info': {
      flex: 1,
      minWidth: 0,
    },
  })
);

export default function IncomingCallContent({ data, callback }) {
  const { user } = data;
  const title = user?.full_name ?? user?.identity;

  const classes = useStyles();

  const url = getPageUrl('MESSAGES', {
    id: data.user.uuid ?? data.user.id,
    target: 'chat',
    state: 'whiteboard',
  });

  return (
    <div className={classes['User-details']}>
      <Avatar src={getApiImages(data.user?.image)} />
      <div className={classes['User-info']}>
        <div className="User-info-toprow">
          <div className="User-info-name">
            <Typography variant="body2" color="textPrimary" weight="semibold">
              {title}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="caption" color="textSecondary" weight="regular">
            has started a video call
          </Typography>
        </div>
      </div>
      <div>
        <Link href={asRoutes.MESSAGES} as={url} shallow>
          <Button aria-label="Answer Call" startIcon={<PhoneCallback />}>
            JOIN
          </Button>
        </Link>
      </div>
    </div>
  );
}
