import { Client } from '@twilio/conversations';
import debounce from 'lodash/debounce';

import {
  receiveMessage,
  getConversations,
  disableConversation,
  removeMessage,
  updateMessage,
  updatedMessage,
  systemMessageAction,
} from '../state/actions';
import { mapConversations, mapMessages, mapMessage } from './mappers';
import TwilioChannel from './TwilioChannel';
import isUserMessage from './helpers/isUserMessage';
import { createOrJoinChannel, generateChannelName } from './utils';
import {
  ConversationType,
  NO_CHAT_MESSAGES,
  SystemMessageTypes,
} from '../state/constants';
import { subtypeRendereders } from '../utils/renderers';

const MESSAGING_PAGE_SIZE = 40;

// This function exists solely to have twilio-chat library code-splitted from
// the main bundle. Webpack is able to do that even though 'twilio-chat' is
// imported statically in this same file and others.
async function initialize(token) {
  return new Client(token);
}

const errorQueue = [];
const withClientRecreation = (target, original) => {
  return async function (...args) {
    try {
      return await original.apply(target, args);
    } catch (e) {
      // eslint-disable-next-line
      console.debug('[Twilio] timeout or connection error, recreate client', e);
      // await target.recreateClient();
      // return original.apply(target, args);
    }
  };
};

class ChatService {
  clientPromise;
  user;
  channelMap = {};
  conversations;
  dispatch;
  fetchToken;

  isConnected;

  // EVENT HANDLERS
  handleTokenAboutToExpire = async () => {
    // eslint-disable-next-line
    console.debug('[Twilio] Token about to expire');
    const token = await this.fetchToken();
    const client = await this.getClient();
    return client.updateToken(token);
  };

  handleTokenExpired = async () => {
    // eslint-disable-next-line
    console.debug('[Twilio] Token expired');
    // Calling `client.updateToken() then token is expired doesn't work. The
    // client remains in a zombie state and doesn't load or send messages. So we
    // initialize a new client instead.
    this.recreateClient();
  };

  handleMessageAdded = async m => {
    if (!isUserMessage(m)) {
      this.handleSystemMessage(m);
      const renderable = subtypeRendereders[m.attributes?.subtype];
      if (!renderable) return;
    }
    const channelSid = m.conversation.sid;
    const findFn = c => c.channelSid === channelSid;
    const conversation = this.conversations.find(findFn);
    if (!conversation) return;
    if (m.author === this.user.identity) {
      this.setLastMessageIndex(channelSid, m.index);
    }
    const message = mapMessage(m, this.user, conversation);
    this.dispatch(receiveMessage(message));
    const channel = await this.getChannel(channelSid);
    channel.addRawMessage(m);
  };

  handleMessageRemoved = async m => {
    const channelSid = m.conversation.sid;
    const findFn = c => c.channelSid === channelSid;
    const conversation = this.conversations.find(findFn);
    if (!conversation) return;
    this.dispatch(removeMessage(m.sid, conversation.id));
  };

  handleMessageUpdated = async ({ message: m }) => {
    const channelSid = m.conversation.sid;
    const findFn = c => c.channelSid === channelSid;
    const conversation = this.conversations.find(findFn);
    if (!conversation) return;
    const message = mapMessage(m, this.user, conversation);
    setTimeout(
      () => this.dispatch(updatedMessage(m.sid, message, conversation)),
      100
    );
  };

  handleSystemMessage = async m => {
    const attributes = m.attributes;
    if (attributes.subtype === SystemMessageTypes.DISABLE_CONVERSATION) {
      this.dispatch(disableConversation(m.conversation.sid));
      return false;
    } else if (m.author !== this.user.id) {
      const renderable = await this.dispatch(systemMessageAction(attributes));
      return renderable;
    }
  };

  debouncedGetConversations = debounce(() => {
    this.dispatch(getConversations());
  }, 1000);

  handleChannelJoined = channel => {
    if (!this.channelMap[channel.sid]) {
      // this.debouncedGetConversations();
    }
  };

  handleConnectionError = error => {
    // eslint-disable-next-line
    console.debug('[Twilio] Connection error', error);
  };

  handleConnectionStateChanged = async state => {
    // eslint-disable-next-line
    console.debug('[Twilio] Connection state changed to', state);
    if (state === 'connected') {
      if (!this.conversations) {
        await this.dispatch(getConversations());
      }
      this._connectionResolve(state);
    }
  };

  addListeners = client => {
    client.on('tokenExpired', this.handleTokenExpired);
    client.on('tokenAboutToExpire', this.handleTokenAboutToExpire);
    client.on('messageAdded', this.handleMessageAdded);
    client.on('messageRemoved', this.handleMessageRemoved);
    client.on('messageUpdated', this.handleMessageUpdated);
    client.on('channelJoined', this.handleChannelJoined);
    client.on('connectionError', this.handleConnectionError);
    client.on('connectionStateChanged', this.handleConnectionStateChanged);
  };

  removeListeners = client => {
    client.off('tokenExpired', this.handleTokenExpired);
    client.off('tokenAboutToExpire', this.handleTokenAboutToExpire);
    client.off('messageAdded', this.handleMessageAdded);
    client.off('messageRemoved', this.handleMessageRemoved);
    client.off('messageUpdated', this.handleMessageUpdated);
    client.off('channelJoined', this.handleChannelJoined);
    client.off('connectionError', this.handleConnectionError);
    client.off('connectionStateChanged', this.handleConnectionStateChanged);
  };
  // END EVENT HANDLERS

  sendMessage = async (body, channelSid, attributes) => {
    const channel = await this.getChannel(channelSid);
    try {
      const m = await channel.sendMessage(body, attributes);
      return mapMessage(
        m,
        this.user,
        this.conversations.find(c => c.channelSid === channelSid)
      );
    } catch (e) {
      // console.log(e);
    }
  };

  deleteMessage = async (messageSid, channelSid) => {
    const channel = await this.getChannel(channelSid);
    return channel.deleteMessage(messageSid);
  };

  updateMessage = async (messageSid, body, channelSid) => {
    const channel = await this.getChannel(channelSid);
    return mapMessage(
      await channel.updateMessage(messageSid, body),
      this.user,
      this.conversations.find(c => c.channelSid === channelSid)
    );
  };

  updateAttributes = async (messageSid, attributes, channelSid) => {
    const channel = await this.getChannel(channelSid);
    return mapMessage(
      await channel.updateAttributes(messageSid, attributes),
      this.user,
      this.conversations.find(c => c.channelSid === channelSid)
    );
  };

  getMessage = async (messageSid, channelSid) => {
    const channel = await this.getChannel(channelSid);
    return mapMessage(
      channel.getMessage(messageSid),
      this.user,
      this.conversations.find(c => c.channelSid === channelSid)
    );
  };

  setLastMessageIndex = async (channelSid, index) => {
    const channel = await this.getChannel(channelSid);
    return channel.advanceLastConsumedMessageIndex(index);
  };

  createClient = async token => {
    try {
      this.isConnected = new Promise((resolve, reject) => {
        this._connectionResolve = resolve;
        this._connectionReject = reject;
      });
      const client = await initialize(token);
      this.addListeners(client);
      return client;
    } catch (e) {
      // eslint-disable-next-line
      console.debug('[Twilio] Client create error', e);
    }
  };

  constructor(token, dispatch, user) {
    this.clientPromise = this.createClient(token);
    this.user = user;
    this.dispatch = dispatch;
  }

  recreateClient = async () => {
    try {
      const client = await this.clientPromise;
      this.removeListeners(client);
      await client.shutdown();
    } catch (e) {
    } finally {
      const token = await this.fetchToken();
      this.clientPromise = this.createClient(token);
      // Bust channels cache to avoid referencing old client via getChannel().
      this.channelMap = {};
    }
  };

  shutdownClient = async () => {
    try {
      const client = await this.clientPromise;
      this.removeListeners(client);
      await client.shutdown();
      this.clientPromise = undefined;
      this.channelMap = {};
    } catch (e) {
    }
  }

  getChannel = withClientRecreation(this, async channelSid => {
    if (!this.channelMap[channelSid]) {
      const client = await this.getClient();
      const channel = TwilioChannel.getConversationBySid(client, channelSid);
      if (!channel) return null;
      this.channelMap[channelSid] = channel;
      this.channelMap[channel.uniqueName] = channel;
    }
    return this.channelMap[channelSid];
  });

  getChannelByName = withClientRecreation(this, async channelName => {
    if (!this.channelMap[channelName]) {
      const client = await this.getClient();
      const channel = await TwilioChannel.getConversationByName(client, channelName);
      if (!channel) return null;
      this.channelMap[channelName] = channel;
      this.channelMap[channel.sid] = channel;
    }
    return this.channelMap[channelName];
  });

  addChannel = channel => {
    const twilioChannel = TwilioChannel.create(channel);
    this.channelMap[channel.uniqueName] = twilioChannel;
    this.channelMap[channel.sid] = twilioChannel;
    return twilioChannel;
  };

  getClient = withClientRecreation(this, async () => {
    if (!this.clientPromise) {
      this.clientPromise = this.createClient().then(() => this.isConnected);
    }

    return this.clientPromise;
  });

  getRemainingChannels = async function(channels, items = []) {
    items.push(...channels.items);
    if (channels.hasNextPage) {
      return channels.nextPage().then(pag => {
        return this.getRemainingChannels(pag, items);
      })
    } else {
      return items;
    }
  }

  getConversations = withClientRecreation(
    this,
    async (type = ConversationType.Active) => {
      const client = await this.getClient();
      // const channel = await createOrJoinChannel(client);
      // const members = [];
      // const members = await channel.getMembers();
      const channels = await client.getSubscribedConversations();
      channels.items = await this.getRemainingChannels(channels);
      console.log(channels);
      // This data might be added lazily as requested later
      // but now we need it from the start anyway so we might as well get it
      const enrichedConversations = await Promise.all(
        channels.items
          .filter(c => c.uniqueName !== 'general')
          .map(async (channel, i) => {
            console.log(channel);
            const channelName = channel.uniqueName;
            // const channelName = generateChannelName(
            //   this.user.identity,
            //   item.state.identity
            // );
            try {
              if (channel?.channelState?.status && channel?.channelState?.status !== 'joined') {
                try {
                  await channel.join();
                } catch (e) {
                  // eslint-disable-next-line
                  console.debug('[Twilio] Failed to join channel', channel, e);
                }
              }
              const members = await channel.getParticipants();
              const conversation = {
                channelName,
                channelSid: channel.sid,
                sentMessages: [],
                messages: [],
                unreadCount: 0,
                firstMessageIndex: 0,
                awaitingChannel: false,
                dateCreated: channel.dateCreated,
                dateUpdated: channel.dateUpdated,
              };

              let classData, user;
              if (channel.attributes?.type === ConversationType.WhiteBoard) {
                conversation.type = ConversationType.WhiteBoard;
                conversation.id = `whiteboard${channel.attributes?.associatedChannel}`;
              } else if (channel.attributes?.students) {
                classData = channel.attributes?.classData || channel.attributes;
                conversation.type = ConversationType.Group;
                conversation.id = classData.uuid;
                conversation.classData = classData;
              } else if (members.length === 2) {
                const counterParty = members.find(
                  m => m.identity !== this.user.identity
                );
                try {
                  user = await client.getUser(counterParty.identity);
                } catch (err) {}
                conversation.type = ConversationType.Active;
                conversation.id = user.identity;
                conversation.user = user && {
                  ...user.attributes,
                  identity: user.identity,
                };
              } else {
                return null;
              }

              if (channelName) {
                const twilioChannel = await this.addChannel(channel);
                const lastMessage = await twilioChannel.getLastMessage();
                if (lastMessage) {
                  conversation.unreadCount =
                    await twilioChannel.getUnreadMessageCount();
                  conversation.lastMessage = mapMessage(
                    lastMessage,
                    this.user,
                    conversation
                  );
                }
              }
              return conversation;
            } catch (e) {
              // eslint-disable-next-line
              console.debug('[Twilio] create conversation error', e);
              return null;
            }
          })
      );

      this.conversations = enrichedConversations.filter(c => !!c);

      return this.conversations;
    }
  );

  getMessages = withClientRecreation(this, async (conversation, anchor) => {
    if (!conversation.channelSid && !conversation.channelName) {
      return NO_CHAT_MESSAGES;
    }
    const channel = await this.getChannelByName(conversation.channelName);
    const messages = await channel.getMessages(MESSAGING_PAGE_SIZE, anchor);

    return {
      ...messages,
      firstMessageIndex: messages.items?.[0]?.index,
      items: mapMessages(messages.items, this.user, conversation),
    };
  });

  getConversationChannel = withClientRecreation(this, async conversation => {
    const client = await this.getClient();
    let channel;
    if (conversation.channelSid) {
      channel = await this.getChannel(client, conversation.channelSid);
    } else {
      channel = await createOrJoinChannel(client, conversation.channelName);
      try {
        await channel.add(conversation.user.identity);

      } catch (e) {
        // eslint-disable-next-line
        console.debug('[Twilio] Failed to invite user', e);
      }
    }
    return channel;
  });

  /**
   * When outer sources update conversation (especially `channelSid`),
   * it is mandatory to sync such conversation with ChatService;
   * otherwise problems might ensue.
   */
  syncConversation = conversation => {
    const findFn = c => c.id === conversation.id;
    const index = this.conversations.findIndex(findFn);
    if (index !== -1) {
      this.conversations[index] = conversation;
    } else {
      this.conversations.push(conversation);
    }
  };

  replaceChannel = channelName => {
    const oldChannel = this.channelMap[channelName];
    delete this.channelMap[oldChannel.channelSid];
    this.getChannelByName(channelName);
  };
}

export default ChatService;
