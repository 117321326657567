import { STUDENT_ROUTES, TUTOR_ROUTES, COMMON_ROUTES } from '../routes';

const urls = {
  ...STUDENT_ROUTES.PRIVATE,
  ...STUDENT_ROUTES.PUBLIC,
  ...TUTOR_ROUTES.PRIVATE,
  ...TUTOR_ROUTES.PUBLIC,
  ...COMMON_ROUTES.PRIVATE,
};

export const asRoutes = {
  MESSAGES: '/app/messages/[...params]',
};

export function getPageUrl(name, params = {}) {
  const url = urls[name];
  return paramReplace(url, params);
}

export function paramReplace(string, params) {
  Object.keys(params).forEach(k => {
    const re = new RegExp(`:${k}(\\(\\\\d\\+\\))?[?]{0,1}`);
    string = params[k]
      ? string.replace(re, params[k] || '')
      : string.replace(re, '');
  });
  const anyParam = new RegExp(':([a-z]+)(\\(\\\\d\\+\\))?[?]{0,1}');
  return string
    .replace(anyParam, '')
    .replace(/\/{2,}/g, '/')
    .replace(/\/$/, '');
}
