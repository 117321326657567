import React, {
  createContext,
  useReducer,
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';

import reducer from './reducer';
import { getDefaultState, ActionType, selectors } from '.';
import ChatService from '../chat-service';
import { createGroupConversations, createDirectConversations } from './actions';

const initialState = getDefaultState();
const store = createContext(initialState);
const { Provider } = store;

export function useChatContext() {
  const context = useContext(store);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatStateProvider');
  }
  return context;
}

const ChatStateProvider = ({
  children,
  token,
  identity,
  fetchToken,
  setToken,
  userDetails,
  classes,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [service, setService] = useState();

  const totalUnreadCount = selectors.totalUnreadCount(state);
  const currentUnreadCount = selectors.currentConversation(state)?.unreadCount;

  const stateRef = useRef(state);
  const serviceRef = useRef();
  const identityRef = useRef(identity);

  const getState = () => stateRef.current;

  const dispatchWrapper = action => {
    try {
      if (typeof action === 'function') {
        return action(dispatchWrapper, getState, {
          chatService: serviceRef.current,
        });
      } else {
        return dispatch(action);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('[Chat Context] Error dispatching action:', action);
    }
  };

  const createChatService = async () => {
    const service = new ChatService(token, dispatchWrapper, {
      ...userDetails,
      identity,
    });
    service.fetchToken = fetchToken;
    serviceRef.current = service;
    dispatchWrapper({ type: ActionType.RESET_CONVERSATIONS });
    await service.isConnected;
    setService(service);
    identityRef.current = identity;
    const client = await service.getClient();
    const user = await client.getUser(identity);

    user.updateAttributes(userDetails);
  };

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    if (service && classes && userDetails.id) {
      const groupclasses = classes.filter(c => c.participants !== 1);
      const directclasses = classes.filter(c => c.participants === 1);
      dispatchWrapper(createGroupConversations(groupclasses));
      dispatchWrapper(createDirectConversations(directclasses, userDetails.id));
    }
  }, [classes, userDetails.id]);

  useEffect(() => {
    if (
      serviceRef.current &&
      identityRef.current &&
      identity &&
      identityRef.current !== identity
    ) {
      identityRef.current = identity;
      const client = serviceRef.current.getClient().then(async client => {
        await client.shutdown();
        setToken('')
        return createChatService();
      });
    }
  }, [identity]);

  useEffect(() => {
    if (!token) {
      return;
    } else if (!serviceRef.current && identity) {
      createChatService();
    } else if (!identity && serviceRef.current) {
      identityRef.current = identity
      serviceRef.current.shutdownClient();
      serviceRef.current = undefined;
      setService(undefined);
      setToken('')
      dispatchWrapper({ type: ActionType.RESET_CONVERSATIONS });
    }
  }, [token, dispatch, identity]);

  return (
    <Provider
      value={{
        state,
        dispatch: dispatchWrapper,
        service,
        totalUnreadCount,
        currentUnreadCount,
        userDetails,
        identity,
      }}
    >
      {children}
    </Provider>
  );
};

export const useSelector = selector => {
  const { state } = useContext(store);
  return selector(state);
};

export const useDispatch = () => {
  const { dispatch } = useContext(store);
  return dispatch;
};

export { store, ChatStateProvider };
