import React from 'react';
import format from 'date-fns/format';
import { Alert } from '@material-ui/lab';
import Call from '@material-ui/icons/Call';

import useStyles from './styles';

const CallStarted = ({ message }) => {
  const { timestamp, author, fullName } = message;
  const time = format(timestamp, 'hh:mm A');

  const classes = useStyles();

  return (
    <div className={classes.system}>
      <Alert severity="info" icon={<Call />}>
        Video Call was started by{' '}
        <span className={classes.user}>{fullName}</span> at{' '}
        <span className={classes.user}>{time}</span>
      </Alert>
    </div>
  );
};

export default CallStarted;
