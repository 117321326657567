import { ConversationType } from './constants';

export const ActionType = {
  SET_CHANNEL_UNREAD_MESSAGE_COUNT: 'SET_CHANNEL_UNREAD_MESSAGE_COUNT',
  SET_CURRENT_CONVERSATION: 'SET_CURRENT_CONVERSATION',
  SET_CONVERSATIONS: 'SET_CONVERSATIONS',
  GET_MESSAGES_PENDING: 'GET_MESSAGES_PENDING',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_ERROR: 'GET_MESSAGES_ERROR',
  SEND_MESSAGE: 'SEND_MESSAGE',
  RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  CREATE_CHANNEL: 'CREATE_CHANNEL',
  CREATE_CHANNEL_SUCCESS: 'CREATE_CHANNEL_SUCCESS',
  AWAIT_CHANNEL: 'AWAIT_CHANNEL',
  DISABLE_CONVERSATION: 'DISABLE_CONVERSATION',
  RESET_CONVERSATIONS: 'RESET_CONVERSATIONS',
  CREAT_MULTI_CONVERSATION: 'CREAT_MULTI_CONVERSATION',
  ADD_CONVERSATIONS: 'ADD_CONVERSATIONS',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  SET_USER: 'SET_USER',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_UI_STATE: 'SET_UI_STATE',
};

export const selectors = {
  totalUnreadCount: state =>
    state.conversations.reduce((total, c) => total + c.unreadCount, 0),
  conversations: (state, type = ConversationType.Active) =>
    state.conversations.filter(c => c.type !== ConversationType.WhiteBoard),
  currentConversation: state => {
    return state.conversations.find(c => c.id === state.currentConversationId);
  },
  getConversation: (state, conversationId) =>
    state.conversations.find(c => c.id === conversationId),
};

export function getDefaultState() {
  return {
    conversations: [],
  };
}
