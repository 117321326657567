export const ConversationType = {
  Active: 'Active',
  Group: 'Group',
  ReadOnly: 'ReadOnly',
  WhiteBoard: 'WhiteBoard',
  Public: 'Public',
};

export const SystemMessageTypes = {
  DISABLE_CONVERSATION: 'DISABLE_CONVERSATION',
  START_VIDEO_CALL: 'START_VIDEO_CALL',
};

export const NO_CHAT_MESSAGES = {
  items: [],
  hasNextPage: false,
  hasPrevPage: false,
};
