import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

import { getDefaultState, ActionType } from '.';
import { ConversationType } from './constants';

export default function reducer(state = getDefaultState(), { type, payload }) {
  switch (type) {
    case ActionType.SET_CHANNEL_UNREAD_MESSAGE_COUNT: {
      const { channel, count } = payload;
      const conversation = state.conversations.find(
        c => c.channelSid === channel
      );
      if (!conversation) {
        return state;
      }
      const newCount =
        conversation.id === state.currentConversationId ? 0 : count;
      if (newCount === conversation.unreadCount) {
        return state;
      }
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          conversation.id,
          {
            unreadCount: newCount,
          }
        ),
      };
    }
    case ActionType.SET_CURRENT_CONVERSATION:
      return {
        ...state,
        ui: {},
        currentConversationId: payload,
      };
    case ActionType.SET_CONVERSATIONS: {
      const conversations = uniqBy([...state.conversations, ...payload], 'id');
      return {
        ...state,
        conversations: sortConversations(conversations),
      };
    }

    case ActionType.RESET_CONVERSATIONS: {
      return {
        ...state,
        conversations: [],
      };
    }

    case ActionType.GET_MESSAGES_PENDING: {
      const { conversation } = payload;
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          conversation.id,
          {
            loading: true,
          }
        ),
      };
    }

    case ActionType.GET_MESSAGES_SUCCESS: {
      const { conversation, messages, previous } = payload;
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          conversation.id,
          {
            messages: previous
              ? [...messages?.items, ...conversation.messages]
              : messages?.items,
            hasPrevPage: messages?.hasPrevPage,
            hasNextPage: messages?.hasNextPage,
            error: messages?.error,
            firstMessageIndex: messages?.firstMessageIndex,
            loading: false,
          }
        ),
      };
    }

    case ActionType.GET_MESSAGES_ERROR: {
      const { conversation, error } = payload;
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          conversation.id,
          {
            loading: false,
            error,
          }
        ),
      };
    }

    case ActionType.RECEIVE_MESSAGE: {
      const message = payload;
      const index = state.conversations.findIndex(
        c => c.channelSid === message.channelSid
      );
      const conversation = state.conversations[index];
      if (!conversation) {
        return state;
      }
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          conversation.id,
          {
            messages: conversation.messages
              ? [...conversation.messages, message]
              : [],
            lastMessage: message,
          }
        ),
      };
    }

    case ActionType.ADD_MESSAGE: {
      const { body, conversationId } = payload;
      const conversation = state.conversations.find(
        c => c.id === conversationId
      );
      if (!conversation) {
        return state;
      }
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          payload.conversationId,
          {
            sentMessages: [...conversation.sentMessages, { body }],
          }
        ),
      };
    }

    case ActionType.UPDATE_MESSAGE: {
      const { messageId, message, conversationId } = payload;
      const conversation = state.conversations.find(
        c => c.id === conversationId
      );
      if (!conversation) {
        return state;
      }
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          payload.conversationId,
          {
            messages: [
              ...conversation.messages.map(m => {
                if (m.sid === messageId) {
                  return {
                    ...m,
                    ...message,
                  };
                }
                return m;
              }),
            ],
          }
        ),
      };
    }

    case ActionType.REMOVE_MESSAGE: {
      const { identifier, conversationId } = payload;
      const conversation = state.conversations.find(
        c => c.id === conversationId
      );
      if (!conversation) {
        return state;
      }

      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          payload.conversationId,
          {
            messages: [
              ...conversation.messages.filter(m => m.sid !== identifier),
            ],
          }
        ),
      };
    }

    case ActionType.AWAIT_CHANNEL:
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          payload.conversationId,
          {
            awaitingChannel: true,
          }
        ),
      };

    case ActionType.CREATE_CHANNEL_SUCCESS:
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          payload.conversationId,
          {
            channelSid: payload.channelSid,
            awaitingChannel: false,
          }
        ),
      };

    case ActionType.DISABLE_CONVERSATION: {
      const conversation = state.conversations.find(
        c => c.channelSid === payload
      );
      if (!conversation) {
        return state;
      }

      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          conversation.id,
          {
            type: ConversationType.ReadOnly,
          }
        ),
      };
    }

    case ActionType.ADD_CONVERSATIONS: {
      const oldConversations = state.conversations.filter(
        nc => !payload.find(c => c.id === nc.id)
      );

      return {
        ...state,
        conversations: sortConversations([...oldConversations, ...payload]),
      };
    }

    case ActionType.UPDATE_CONVERSATION: {
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          payload.id,
          payload
        ),
      };
    }

    case ActionType.SET_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          [payload.id]: payload,
        },
      };
    }

    case ActionType.SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: payload,
      };
    }

    case ActionType.SET_UI_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.key]: payload.value,
        },
      };
    }

    default:
      return state;
  }
}

/**
 * Removes duplicates of messages
 * and reorders them based on messages send to twilio directly don't have a timestamp but have a unique index
 * @param messages The list of messages to deduplicate / reorder from a single channel
 */

function filterMessages(messages) {
  return messages.sort((a, b) => {
    return a.index - b.index;
  });
}

function updateConversation(conversations, conversationId, params) {
  const index = conversations.findIndex(c => c.id === conversationId);
  if (index < 0) {
    return conversations;
  }
  const conversation = conversations[index];
  const updatedConversation = {
    ...conversation,
    ...params,
  };
  if (params.messages) {
    updatedConversation.messages = filterMessages(params.messages);
  }

  return sortConversations([
    ...conversations.slice(0, index),
    updatedConversation,
    ...conversations.slice(index + 1),
  ]);
}

function sortConversations(conversations) {
  const hasLastMessage = c => Boolean(c.lastMessage);
  const lastMessageTime = c => c.lastMessage?.timestamp;
  const isGroup = c => c.type === ConversationType.Group;

  return orderBy(
    conversations,
    [isGroup, hasLastMessage, lastMessageTime],
    ['desc', 'desc', 'desc']
  );
}
