import findLast from 'lodash/findLast';
import keyBy from 'lodash/keyBy';

import isUserMessage from '../helpers/isUserMessage';
import { subtypeRendereders } from '../../utils/renderers';

const NO_MESSAGES = {
  items: [],
  hasNextPage: false,
  hasPrevPage: false,
};

const LAST_MESSAGE_DIG_LIMIT = 5;

/**
 * Wrapper around pure Twilio channel.
 *
 * We shouldn't assume that we can always obtain a pure Twilio channel.
 *
 * This wrapper allows us transparently handle operations when we can't,
 * reporting the problem but preserving the same homogeneous API.
 */
class TwilioChannel {
  _sid;
  get sid() {
    return this._sid;
  }

  _pureChannel;
  _errorMessage;
  _rawMessages = {};

  static async getConversationBySid(twilioClient, channelSid) {
    try {
      const pureChannel = await twilioClient.getConversationBySid(channelSid);
      return TwilioChannel.create(pureChannel);
    } catch (e) {
      return TwilioChannel.createWithError(channelSid, e.message);
    }
  }

  static async getConversationByName(twilioClient, channelName) {
    try {
      const pureChannel = await twilioClient.getConversationByUniqueName(
        channelName
      );
      return TwilioChannel.create(pureChannel);
    } catch (e) {
      return null;
    }
  }

  static create(channel) {
    const twilioChannel = new TwilioChannel(channel.sid);
    twilioChannel._pureChannel = channel;
    return twilioChannel;
  }

  static createWithError(sid, error) {
    const twilioChannel = new TwilioChannel(sid);
    twilioChannel._errorMessage = error;
    return twilioChannel;
  }

  constructor(sid) {
    this._sid = sid;
  }

  get channel() {
    return this._pureChannel;
  }

  get error() {
    return this._errorMessage;
  }

  get isConnected() {
    return !!this._pureChannel;
  }

  async getUnreadMessageCount() {
    if (!this._pureChannel) return 0;
    try {
      let count = await this._pureChannel.getUnreadMessagesCount();
      if (Number.isFinite(count)) return count;

      count = await this._pureChannel.getMessagesCount();
      if (Number.isFinite(count)) return count;
    } catch {}

    return 0;
  }

  /**
   * Returns the last "ordinary" message from this channel.
   */
  async getLastMessage() {
    if (this._pureChannel) {
      // we need to get more than one message here to select from
      // because some messages might be sent by system
      try {
        const page = await this._pureChannel.getMessages(
          LAST_MESSAGE_DIG_LIMIT
        );
        return findLast(page.items, isUserMessage);
      } catch (e) {
        return null;
      }
    }
  }

  /**
   * Returns list of all ordinary messages from this channel.
   */
  async getMessages(pageSize = 30, anchor) {
    if (!this._pureChannel) return NO_MESSAGES;

    const messages = await this._pureChannel.getMessages(pageSize, anchor);
    this._rawMessages = {
      ...this._rawMessages,
      ...keyBy(messages.items, 'sid'),
    };

    await this.setAllMessagesRead();

    return {
      items: messages.items.filter(
        m => isUserMessage(m) || subtypeRendereders[m.attributes?.subtype]
      ),
      hasNextPage: messages.hasNextPage,
      hasPrevPage: messages.hasPrevPage,
    };
  }

  async sendMessage(body, attributes = undefined) {
    if (this._pureChannel) {
      if (body instanceof File) {
        const formData = new FormData();
        formData.append('file', body);
        body = formData;
      }

      const index = await this._pureChannel.sendMessage(body, attributes);
      const messages = await this._pureChannel.getMessages(1, index);
      const m = messages?.items?.[0];
      m && (this._rawMessages[m.sid] = m);
      return m;
    }
  }

  async deleteMessage(identifier) {
    const m = await this._rawMessages[identifier].remove();
    delete this._rawMessages[identifier];
    return m;
  }

  async updateMessage(identifier, body) {
    const m = await this._rawMessages[identifier].updateBody(body);
    this._rawMessages[identifier] = m;
    return m;
  }

  getMessage(identifier) {
    return this._rawMessages[identifier];
  }

  async updateAttributes(identifier, attributes) {
    const m = await this._rawMessages[identifier].updateAttributes(attributes);
    this._rawMessages[identifier] = m;
    return m;
  }

  addRawMessage(m) {
    this._rawMessages[m.sid] = m;
  }

  async advanceLastConsumedMessageIndex(index) {
    if (this._pureChannel) {
      try {
        return this._pureChannel.advanceLastReadMessageIndex(index);
      }
      catch (e) {
        console.log("[Twilio] advanceLastConsumedMessageIndex error", e);
      }
    }
  }

  async setAllMessagesRead() {
    if (this._pureChannel) {
      // console.log("setAllMessagesRead", this._pureChannel);
      try {
        return this._pureChannel.setAllMessagesRead();
      }
      catch (e) {
        console.log("[Twilio] setAllMessagesRead error", e);
      }
    }
  }
}

export default TwilioChannel;
