import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    system: {
      marginBottom: theme.spacing(1),
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& .MuiAlert-root': {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        whiteSpace: 'nowrap',
      },
      '& .MuiAlert-message': {
        fontSize: 12,
        whiteSpace: 'nowrap',
      },
    },
    user: {
      fontWeight: 500,
    },
  })
);

export default useStyles;
