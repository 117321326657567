import emoji from 'node-emoji';

function mapConversation(c, type) {
  return {
    id: c.engagement.id,
    job: c.engagement.job,
    talent: c.engagement.talent,
    channelSid: c.channel,
    messages: [],
    unreadCount: 0,
    tracking: {
      engagementId: c.engagement.jsonapiId,
    },
    firstMessageIndex: 0,
    awaitingChannel: false,
    sentMessages: [],
    type,
  };
}

export function mapConversations(conversations, type) {
  return conversations.map(c => mapConversation(c, type));
}

export function mapMessages(messages, user, conversation) {
  return messages.map(m => mapMessage(m, user, conversation));
}

export function mapMessage(m, user, conversation) {
  let { photo, full_name: fullName } = user;
  let fromUser = true;
  if (m?.author !== user.identity) {
    photo = conversation.user?.photo;
    fullName = conversation.user?.full_name || m?.author;
    fromUser = false;
  }

  const attributes = m.attributes;

  const data = {
    timestamp: m.dateCreated,
    updated: m.dateUpdated,
    author: m.author,
    body: emoji.emojify(m.body, null, null),
    sid: m.sid,
    index: m.index,
    type: m.type,
    media: m.media,
    subtype: attributes && attributes.subtype,
    channelSid: m.conversation.sid,
    photo,
    fullName,
    fromUser,
    attributes,
  };
  return data;
}
