import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';

import { ActionType, selectors } from '.';
import { ConversationType, SystemMessageTypes } from './constants';
import {
  wait,
  createOrJoinChannel,
  generateChannelName,
  createTwilioConversation,
} from '../chat-service/utils';
import TwilioChannel from '../chat-service/TwilioChannel';
import { buildPath } from '../../../api/base';
import IncomingCallContent from '../../communication/components/IncomingCallContent';

export function setChannelUnreadMessageCount(channel, count) {
  return {
    type: ActionType.SET_CHANNEL_UNREAD_MESSAGE_COUNT,
    payload: { channel, count },
  };
}

export function getMessages(conversation, previous = false) {
  return async (dispatch, getState, extraArgs) => {
    dispatch({
      type: ActionType.GET_MESSAGES_PENDING,
      payload: { conversation },
    });

    try {
      const hasMessages = previous && conversation.messages.length > 0;
      const anchor = hasMessages
        ? conversation.firstMessageIndex - 1
        : undefined;
      const messages = await extraArgs.chatService.getMessages(
        conversation,
        anchor
      );

      dispatch({
        type: ActionType.GET_MESSAGES_SUCCESS,
        payload: { messages, conversation, previous },
      });

      // for current conversation we should also reset unread message count
      const state = getState();
      const sid = conversation.channelSid;
      const isCurrent = state.currentConversationId === conversation.id;

      if (isCurrent && sid) {
        dispatch(setChannelUnreadMessageCount(sid, 0));
      }
    } catch (e) {
      dispatch({
        type: ActionType.GET_MESSAGES_ERROR,
        payload: { error: e.message, conversation },
      });
    }
  };
}

export function setCurrentConversation(conversationId) {
  return async (dispatch, getState, extraArgs) => {
    let state = getState();
    const { chatService } = extraArgs;

    if (state.currentConversationId === conversationId) {
      return;
    }

    dispatch({
      type: ActionType.SET_CURRENT_CONVERSATION,
      payload: conversationId,
    });

    const conversation = selectors.getConversation(state, conversationId);
    if (!conversation) return;

    if (conversation.type === ConversationType.Active) {
      await axios.get(buildPath(`/${conversationId}/start-chat`)).catch(
        () => console.error(`Failed to connect with user ${conversationId}`) // eslint-disable-line
      );
    }

    if (conversation.channelSid) {
      dispatch(setChannelUnreadMessageCount(conversation.channelSid, 0));
    } else {
      const channel = await chatService.getConversationChannel(conversation);
      const channelSid = channel?.sid;
      dispatch(createChannelSuccess(conversation.id, channelSid));
      dispatch(setChannelUnreadMessageCount(channelSid, 0));
    }
    await wait();
    state = getState();
    const newConversation = state.conversations.find(
      c => c.id === conversationId
    );
    newConversation && chatService.syncConversation(newConversation);
    if (!conversation.firstMessageIndex) {
      dispatch(getMessages(newConversation));
    }
  };
}

export function setConversations(conversations) {
  return {
    type: ActionType.SET_CONVERSATIONS,
    payload: conversations,
  };
}

export function awaitChannel(conversationId) {
  return {
    type: ActionType.AWAIT_CHANNEL,
    payload: {
      conversationId,
    },
  };
}

export function createChannelSuccess(conversationId, channelSid) {
  return {
    type: ActionType.CREATE_CHANNEL_SUCCESS,
    payload: {
      conversationId,
      channelSid,
    },
  };
}

function createChannelConversation(conversationId) {
  return {
    type: ActionType.CREATE_CHANNEL,
    meta: {},
  };
}

export function createChannel(conversation) {
  return async (dispatch, getState, extraArgs) => {
    if (!conversation.channelSid && !conversation.awaitingChannel) {
      dispatch(awaitChannel(conversation.id));
      const chatService = extraArgs.chatService;
      const channel = await chatService.getConversationChannel(conversation);
      const channelSid = channel.sid;
      dispatch(createChannelSuccess(conversation.id, channelSid));
      await wait();

      // NOTE: after dispatch to the `createChannel` conversation could be updated
      // this is why we need to re-fetch `newConversation` from the store and
      // sync it with the chat service
      const state = getState();
      const newConversation = selectors.getConversation(state, conversation.id);
      if (newConversation && newConversation.channelSid) {
        chatService.syncConversation(newConversation);
        // Once the channel has been created we should forward all the messages
        // as they have not been sent
        newConversation.sentMessages.forEach(async ({ body, attributes }) =>
          dispatch(forwardMessage(body, newConversation, attributes))
        );
      }
    }
  };
}

export function sendMessage(body, conversation, attributes) {
  return async (dispatch, getState) => {
    if (typeof conversation === 'string') {
      const state = getState();
      conversation = selectors.getConversation(state, conversation);
    }
    dispatch(addMessage(body, conversation.id, attributes));

    if (conversation.channelSid) {
      return await dispatch(forwardMessage(body, conversation, attributes));
    } else {
      return await dispatch(createChannel(conversation));
    }
  };
}

export function sendSystemMessage(body, conversation, subtype, attributes) {
  return async (dispatch, getState) => {
    const state = getState();
    return dispatch(
      sendMessage(body, conversation, {
        subtype,
        user: state.currentUser,
        ...attributes,
      })
    );
  };
}

// TODO: we should pass an internal id to this action so that we can indentify messages that are not yet send to the socket
export function deleteMessage(messageId, conversation) {
  return async (dispatch, getState, extraArgs) => {
    if (typeof conversation === 'string') {
      const state = getState();
      conversation = selectors.getConversation(state, conversation);
    }
    if (!conversation) {
      return null;
    }
    if (conversation.channelSid) {
      return await extraArgs.chatService.deleteMessage(
        messageId,
        conversation.channelSid
      );
    } else {
      // TODO: needs to be tested when messages are not yet in a channel
      return await dispatch(removeMessage(messageId, conversation));
    }
  };
}

export function updateMessage(messageId, message, conversation) {
  return async (dispatch, getState, extraArgs) => {
    if (typeof conversation === 'string') {
      const state = getState();
      conversation = selectors.getConversation(state, conversation);
    }
    if (!conversation) {
      return null;
    }
    dispatch(updatedMessage(messageId, message, conversation));
    if (conversation.channelSid) {
      let m = extraArgs.chatService.getMessage(
        messageId,
        conversation.channelSid
      );
      if (message.body) {
        m = await extraArgs.chatService.updateMessage(
          messageId,
          message.body,
          conversation.channelSid
        );
      }
      if (message.attributes) {
        m = await extraArgs.chatService.updateAttributes(
          messageId,
          message.attributes,
          conversation.channelSid
        );
      }
      return m;
    }
  };
}

export function updatedMessage(messageId, message, conversation) {
  return {
    type: ActionType.UPDATE_MESSAGE,
    payload: {
      messageId,
      message,
      conversationId: conversation.id,
    },
  };
}

export function addMessage(body, conversationId, attributes) {
  return {
    type: ActionType.ADD_MESSAGE,
    payload: {
      conversationId,
      body,
      attributes,
    },
  };
}

export function removeMessage(identifier, conversationId) {
  return {
    type: ActionType.REMOVE_MESSAGE,
    payload: {
      conversationId,
      identifier,
    },
  };
}

export function forwardMessage(body, conversation, attributes) {
  return async (dispatch, getState, extraArgs) => {
    if (conversation.channelSid) {
      return await extraArgs.chatService.sendMessage(
        body,
        conversation.channelSid,
        attributes
      );
    }
  };
}

export function receiveMessage(message) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();
    const conversations = selectors.conversations(state);
    const conversation = conversations.find(
      c => c.channelSid === message.channelSid
    );

    if (conversation && conversation.id === state.currentConversationId) {
      // TODO: investigate if we really need dispatching this action because
      //       in ChatService#handleMessageAdded we already have something
      //       similar
      extraArgs.chatService.setLastMessageIndex(
        message.channelSid,
        message.index
      );
    } else if (conversation) {
      const newCount = conversation.unreadCount + 1;
      dispatch(setChannelUnreadMessageCount(message.channelSid, newCount));
    }

    dispatch({
      type: ActionType.RECEIVE_MESSAGE,
      payload: message,
    });
  };
}

export function getConversations(type = ConversationType.Active) {
  return async (dispatch, _getState, extraArgs) => {
    const conversations = await extraArgs.chatService.getConversations(type);
    dispatch(setConversations(conversations));
  };
}

export function disableConversation(channelSid) {
  return {
    type: ActionType.DISABLE_CONVERSATION,
    payload: channelSid,
  };
}

export function addConversations(conversations) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();
    conversations.forEach(c => extraArgs.chatService.syncConversation(c));
    dispatch({
      type: ActionType.ADD_CONVERSATIONS,
      payload: conversations,
    });
  };
}

export function createGroupConversations(groupconversations) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();
    const conversations = selectors.conversations(state);
    groupconversations = groupconversations.filter(
      mc => !conversations.find(c => mc.uuid === c.id)
    );

    const client = await extraArgs.chatService.getClient();

    const mappedConversations = await Promise.all(
      groupconversations.map(async gc => {
        const pureChannel = await createOrJoinChannel(client, gc.uuid);

        const channel = await TwilioChannel.create(pureChannel);
        await pureChannel.updateAttributes(gc);

        await Promise.all(gc.students.map(s => {
            channel.channel.add(s.uuid_user);
        }));

        const conversation = {
          type: ConversationType.Group,
          id: gc.uuid,
          classData: gc,
          channelName: gc.uuid,
          channelSid: channel.channel.sid,
          sentMessages: [],
          messages: [],
          unreadCount: 0,
          firstMessageIndex: 0,
          awaitingChannel: false,
        };

        return conversation;
      })
    );

    dispatch(addConversations(mappedConversations));
  };
}

export function createDirectConversations(directconversations, currentUserId) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();
    const conversations = selectors.conversations(state);

    const client = await extraArgs.chatService.getClient();

    const conversationData = uniqBy(
      directconversations
        .map(classObject => {
          const targetId =
            currentUserId === classObject.tutor_user_uuid
              ? classObject.students[0]?.uuid_user
              : classObject.tutor_user_uuid;

          const userId = currentUserId;

          const existingConversation = conversations.find(
            c => c.id === targetId
          );
          const classData =
            currentUserId === classObject.tutor_user_uuid && classObject;

          if (existingConversation) {
            return null;
          }

          const attributes = {};
          if (classData && currentUserId === classObject.tutor_user_uuid) {
            attributes.classData = classData;
          }

          return {
            targetId,
            userId,
            attributes,
          };
        })
        .filter(c => !!c),
      ({ targetId, userId }) => targetId + userId
    );

    const mappedConversations = await Promise.all(
      conversationData.map(async ({ targetId, userId, attributes }) => {
        const conversation = await createTwilioConversation(
          extraArgs.chatService,
          targetId,
          userId,
          ConversationType.Active,
          attributes
        );

        return conversation;
      })
    );

    dispatch(addConversations(mappedConversations.filter(c => !!c)));
  };
}

export function createConversation(
  id,
  currentUserId,
  type = ConversationType.Active,
  attributes = {},
  currentConversation = null
) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();

    const conversation = selectors.getConversation(state, id);
    if (conversation) {
      type === ConversationType.Active && dispatch(setCurrentConversation(id));
      return conversation;
    } else {
      try {
        const conversation = await createTwilioConversation(
          extraArgs.chatService,
          id,
          currentUserId,
          type,
          attributes,
          currentConversation
        );
        if (!conversation) {
          // eslint-disable-next-line
          console.debug(
            '[Twilio] Failed to create conversation',
            id,
            currentUserId
          );
          return;
        }

        dispatch(addConversations([conversation]));
        if (type === ConversationType.Active ) {
          dispatch(setCurrentConversation(id));
          await axios.get(buildPath(`/${id}/start-chat`)).catch(
            () => console.error(`Failed to connect with user ${id}`) // eslint-disable-line
          );
        }

        dispatch(getMessages(conversation));
        return conversation;
      } catch (e) {
        // eslint-disable-next-line
        console.debug(
          '[Twilio] Failed to create conversation',
          id,
          currentUserId
        );
      }
    }
  };
}

export function clearConversation(id) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();

    const conversation = selectors.getConversation(state, id);
    if (conversation) {
      try {
        const client = await extraArgs.chatService.getClient();
        let pureChannel = await createOrJoinChannel(
          client,
          conversation.channelName
        );

        await pureChannel.delete();
        pureChannel = await createOrJoinChannel(
          client,
          conversation.channelName
        );
        conversation.channelSid = pureChannel.sid;
        conversation.messages = [];
        conversation.sentMessages = [];
        conversation.unreadCount = 0;
        conversation.firstMessageIndex = 0;
        conversation.awaitingChannel = false;
        extraArgs.chatService.syncConversation(conversation);
        extraArgs.chatService.replaceChannel(conversation.channelName);
        dispatch({
          type: ActionType.UPDATE_CONVERSATION,
          payload: conversation,
        });
      } catch (e) {
        // eslint-disable-next-line
        console.debug('[Twilio] Failed to clear conversation', id);
      }
    }
  };
}

export function systemMessageAction(data) {
  return async (dispatch, getState, extraArgs) => {
    const { subtype } = data;
    switch (subtype) {
      case SystemMessageTypes.START_VIDEO_CALL: {
        const content = <IncomingCallContent data={data} />;
        const toastId = toast(content, {
          position: 'bottom-right',
          autoClose: 5000,
          closeOnClick: false,
          // closeButton: false,
        });
        return toastId;
      }
    }
  };
}

export function setUIState(key, value) {
  return {
    type: ActionType.SET_UI_STATE,
    payload: {
      key,
      value,
    },
  };
}

export function updateConversationParticipants(groupconversations) {
  return async (dispatch, getState, extraArgs) => {
    const state = getState();
    const conversations = selectors.conversations(state);
    groupconversations = groupconversations.filter(
      mc => conversations.find(c => mc.uuid === c.id)
    );

    const client = await extraArgs.chatService.getClient();

    await Promise.all(
      groupconversations.map(async gc => {
        const pureChannel = await createOrJoinChannel(client, gc.uuid);

        const channel = await TwilioChannel.create(pureChannel);

        await Promise.all(gc.students.map(async s => {
          try {
            await channel.channel.getParticipantByIdentity(s.uuid_user);
          } catch (err) {
            channel.channel.add(s.uuid_user);
          }
        }));
      })
    );
  };
}
